.coming-soon {
    background-image: linear-gradient(rgba(15, 32, 46, 0.7), rgba(15, 32, 46, 0.7)), url("../components/img/coming-soon-church-build.jpeg");
    background-position: right;
    height: auto;
}

.coming-soon {
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 20rem;
    height: auto;
}