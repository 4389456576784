/*html,body,h1,h2,h3,h4,h5 {font-family: "Lato", sans-serif}*/
.mySlides {
    display: none;
}

.w3-left,
.w3-right,
.w3-tag {
    cursor: pointer;
}

.w3-custom-blue,
.w3-hover-cus-blue:hover {
    color: #fff !important;
    background-color: #1573F9 !important
}


.w3-tag {
    height: 15px;
    width: 15px;
    padding: 0;
    margin-top: 6px;
}

.bible-verse {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 30px;
}

#w3-message-greeting h3 {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
}

p.w3--message {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
}

p.w3--message-smaller {
    color: white;
    font-size: 1.3rem;
    font-weight: bold;
}

.w3--message-black {
    color: black;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
}

.w3--message-black-normal {
    color: black;
    font-size: 1.5rem;
    /*font-weight: bold;*/
    text-align: left;
}

.logo-dim {
    width: 150px;
    height: auto;
}

.url-link {
    color: 'black';
}

.arc-cont {
    position: relative;
    display: block;
    margin: 10px auto;
    width: 100%;
}

.arc-cont:after {
    padding-top: 59%;
    display: block;
    content: '';
}

.arc-cont>iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 98%;
    height: 98%;
    border: 0;
}

.iframe-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio 56.25%*/
}

.iframe-container-no-padding-top {
    position: relative;
    width: 100%;
    overflow: hidden;
    /*padding-top: 56.25%;*/
    /* 16:9 Aspect Ratio 56.25%*/
}

.responsive-iframe {
    position: absolute;
    top: 20px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 550px;
    border: none;
}