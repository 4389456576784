ul.short-stories {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #d67e89;
}

.short-stories li {
  float: left;
}

.short-stories li a {
  display: block;
  color: white;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 1.25rem;
}

.short-stories li a:hover {
  background-color: #111111;
}
.iframe-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 66.66%; /* 3:2 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
