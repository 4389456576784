.card {
  padding-left: 1.0rem;
  padding-right: 1.0rem;
}

.form__label {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 1.6rem;
}

.form__input {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: transparent;
  font-size: 1.6rem;
  border: 0.2rem solid #f5f5f5;
  transition: border-color 0.3s ease-out;
}

.form__error {
  display: block;
  width: 100%;
  padding: 1rem;
  background-color: transparent;
  font-size: 1.4rem;
  font-style: italic;
  color: red;
  text-align: left;
  transition: border-color 0.3s ease-out;
}