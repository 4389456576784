button {
    /* padding: 1%; */
    background: none;
    font-size: 1.2rem;
    display: table-cell;
    text-align: left;
}

button:hover {
    background: #ed6a5a;
    color: white;
    /* transition: color 0.3s ease-out; */
    /* font-size: 1.2rem; */
}