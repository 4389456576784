h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto Condensed', sans-serif;
  color: inherit;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  font-size: 3.2rem;
}

a {
  color: #ed6a5a;
  transition: all 0.3s ease-out;
}

a:hover {
  color: #cc5242;
}

.descript-font {
  font-size: 1.5rem;
  color: brown;
}
