.banner {
    background-image: linear-gradient(rgba(15, 32, 46, 0.7), rgba(15, 32, 46, 0.7)), url("../components/img/congregation-ht-loichua.jpg");
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    height: 40rem;
    margin-top: -8rem;
    padding: 15rem 0 10rem;
    color: #f5f5f5;
}

.banner .section-heading {
    margin-bottom: 2.5rem;
}

.banner.single-banner-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10rem 0;
    height: 50rem;
}