.single-banner-yem-minitries {
    background-image: linear-gradient(rgba(15, 32, 46, 0.7), rgba(15, 32, 46, 0.7)), url("../components/img/yem-banner-large.jpg");
}

/* Picnic-YEM-LIFE.jpg*/
.single-yem-ministry__cta {
    background-image: linear-gradient(rgba(15, 32, 46, 0.7), rgba(15, 32, 46, 0.7)), url("../components/img/yem-cta.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* FONT SIZE*/

div.description p {
    font-size: medium;
}

div.responsibilities p {
    font-size: medium;
}

div.details span {
    font-size: medium;
}