.left-align-text {
    text-align: left;
}

/* background bottom for donation pages*/
.donation_bg {
    background-image: linear-gradient(rgba(15, 32, 46, 0.7), rgba(15, 32, 46, 0.7)), url("../img/ht-loichua-photo.jpeg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}