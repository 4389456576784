.Topslide {
    height: 750px;

}

.Topslide_short {
    height: 550px;

}

.Topslide_shortest {
    height: 250px;

}

.carousel {
    width: 400px;
    height: 300px;
    margin: auto;
}

.smaller_img {
    width: 600px;
    height: 480px;
}
.text_white {
  color:aliceblue;
  font-weight: bold;
}
@media (max-width: 900px) {
    .carousel {
        width: auto;
        height: auto;
    }
}